import {
  mapState, mapMutations,
} from 'vuex';
export default {
  name: 'AppNavBar',
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
  },
  methods: {
    ...mapMutations({
      setChangeActiveTabView: 'Projects/setChangeActiveTabView',
    }),
    async goToPage({ link, value }) {
      if (value === 'projects') {
        this.setChangeActiveTabView(null);
      }
      const currentPath = this.$route.path;
      if (link && currentPath !== `${link}`) {
        await this.goTo({
          link,
        });
      }
    },
    customBgColor(index) {
      const mainColors = ['blue', 'yellow', 'green', 'red', 'black'];
      return mainColors[ index % 5 ];
    },
  },
};
