<template>
  <v-list class="d-flex flex-column gap-3">
    <v-list-item
      v-for="staring in staringFilteredViewsSortedByName"
      :key="staring.id"
      :class="{'v-list-item--active': staring.id === activeStaringFilteredViewId}"
      class="starred__item d-flex justify-space-between gap-7"
      @click="setActiveView(staring, { link: getFullLinkForWorkspaces('projects'), value: 'projects'})">
      <div class="text-truncate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon>
              mdi-star
            </v-icon>
            <span
              v-bind="attrs"
              class="starred__item-title w-100"
              v-on="on">
              {{ staring.name }}
            </span>
          </template>
          <span>{{ staring.name }}</span>
        </v-tooltip>
      </div>
      <v-icon
        color="darkGrey"
        class="starred__item-edit"
        @click.stop="$emit('edit-star-view', staring)">
        mdi-pencil
      </v-icon>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapState } from 'vuex';
import { getFullLinkForWorkspaces } from '@/utils';
import AppNavBar from '@/mixins/AppNavBar';
import LoadingSpinnerTimeout from '@/mixins/LoadingSpinnerTimeout';
export default {
  name: 'AppHeaderStarProjects',
  mixins: [LoadingSpinnerTimeout, AppNavBar],
  computed: {
    ...mapState('Projects', ['staringFilteredViews', 'activeStaringFilteredViewId']),
    staringFilteredViewsSortedByName() {
      const { staringFilteredViews } = this;
      return staringFilteredViews.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    },
  },
  methods: {
    getFullLinkForWorkspaces,
    async setActiveView(staringView, item) {
      this.$route.name !== 'projects' && await this.goToPage(item);
      this.setChangeActiveTabView(staringView);
    },
    editViewStaring(staring) {
      this.$refs.editStaring.openWindow(staring);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-menu__content {
  .v-list-item {
    min-height: unset;
    height: auto;
    margin-top: 0;

    &::before, &::after {
      display: none;
    }

    &--active {
      color: var(--v-green-base);
      border-left-color: var(--v-green-base);
    }
  }
}
</style>