<template>
  <app-dialog
    v-model="dialog"
    content-class="v-dialog__form"
    width="400">
    <div class="dialog-content">
      <v-card>
        <v-card-title>
          <span>Rename Starred View</span>
          <span
            class="sourcery__icon-wrapper black pointer"
            @click="dialog = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="nameStaringFilteredView"
            hide-details
            single-line
            dense
            autocomplete="name" />
        </v-card-text>
        <v-card-actions
          class="pt-6 d-flex justify-end">
          <template>
            <v-btn
              outlined
              @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              class="white--text"
              color="blue"
              @click="saveName">
              Save
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
import {
  mapActions,
  mapGetters, mapMutations, mapState,
} from 'vuex';
import ProjectFilterTagsAPI from '@/services/graphql/projectFilterTags';
export default {
  name: 'EditStaringFilteredView',
  data() {
    return {
      activeView: {
      },
      dialog: false,
      nameStaringFilteredView: '',
    };
  },
  computed: {
    ...mapState({
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      staringFilteredViews: state => state.Projects.staringFilteredViews,
    }),
    ...mapGetters({
      projectSortingMode: 'UserProfile/projectSortingMode',
    }),
  },
  methods: {
    ...mapMutations({
      setStaringFilteredViews: 'Projects/setStaringFilteredViews',
      spinner: 'spinner',
    }),
    ...mapActions({
      handleError: 'handleError',
    }),
    openWindow(staring) {
      this.activeView = staring;
      this.nameStaringFilteredView = staring.name;
      this.dialog = true;
    },
    async saveName() {
      try {
        this.spinner(true);
        const {
          activeWorkspaceId: workspaceId,
          projectSortingMode: sortingMode,
          nameStaringFilteredView: name,
        } = this;
        const { id: starredListId } = this.activeView;
        const { data } = await ProjectFilterTagsAPI.updateStarredProjectList({
          workspaceId,
          starredListId,
          sortingMode,
          name,
        });
        const updatedCurrentView = this.staringFilteredViews.map(e => {
          if (e.id === data.response.id) return data.response;
          return e;
        });
        this.setStaringFilteredViews(updatedCurrentView);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
        this.dialog = false;
      }
    },
  },
};
</script>
