var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.mdAndUp && _vm.getActiveWorkspace)?_c('div',{staticClass:"app-tabs v-tabs-wrapper",attrs:{"id":"appTabs"}},[_c('v-tabs',{staticClass:"sourcery__tabs",attrs:{"value":_vm.activeTab,"background-color":"transparent","color":"green","slider-size":"0"}},_vm._l((_vm.tabs),function(ref){
var icon = ref.icon;
var link = ref.link;
var name = ref.name;
var url = ref.url;
return _c('v-tab',{key:url,staticClass:"fs-18 font-fam-poppins pa-0",attrs:{"href":url},on:{"click":function($event){$event.preventDefault();return _vm.redirectTo(url)}}},[_c('i',{class:'nav-svg-icon icon-' + icon}),_vm._v(" "+_vm._s(name)+" "),(link === _vm.ROUTE_PROJECTS && _vm.staringFilteredViews.length)?[_c('v-menu',{attrs:{"min-width":175,"max-width":450,"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"down-icon ms-n2"},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('AppHeaderStarProjects',{on:{"edit-star-view":_vm.editViewStaring}})],1)]:_vm._e()],2)}),1),_c('EditStaringFilteredView',{ref:"editStaring"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }